let cookieModal = document.getElementById('cookie-modal');

if (cookieModal) {
    let cookieSave = document.getElementById('cookie-save');
    let cookieAccept = document.getElementById('cookie-accept');
    let cookieSettings = document.getElementById('cookie-settings');
    let marketingCookieCheckbox = document.getElementById('marketing-cookies');
    let analyticsCookieCheckbox = document.getElementById('analytics-cookies');

    if (marketingCookieCheckbox) {
        var marketingCookieNames = JSON.parse(marketingCookieCheckbox.getAttribute('data-cookie-names'));
        var marketingCookieValues = JSON.parse(marketingCookieCheckbox.getAttribute('data-cookie-values'));
        setCheckbox(marketingCookieCheckbox, marketingCookieNames);
    }

    if (analyticsCookieCheckbox) {
        var analyticsCookieNames = JSON.parse(analyticsCookieCheckbox.getAttribute('data-cookie-names'));
        var analyticsCookieValues = JSON.parse(analyticsCookieCheckbox.getAttribute('data-cookie-values'));
        setCheckbox(analyticsCookieCheckbox, analyticsCookieNames);
    }

    window.addEventListener('load', checkCookie);
    cookieSave.addEventListener('click', save);
    cookieAccept.addEventListener('click', acceptAll);
    cookieSettings.addEventListener('click', showCookieModal);

    //youtube consent
    const videoContainers = document.querySelectorAll('.kd-consent-holder')
    const videoConsentButtons = document.querySelectorAll('.kd-consent-link')

    if (videoContainers) {
        videoConsentButtons.forEach(function(button){
            button.addEventListener('click', acceptMarketing);
        });

        if (getCookie('yt_consent') == 'granted') {
            loadIframes();
        }
    }

    function setCheckbox(checkbox, cookies) {
        if (getCookie(cookies[0])) {
            checkbox.checked = true;
        }
    }

    function checkCookie() {
        if (getCookie('cookie_consent') == null) {
            showCookieModalDelayed();
        }
    }

    function getCookie(cookieName) {
        var documentCookies = document.cookie.split(';');
        for (var i = 0; i < documentCookies.length; i++) {
            var cookie = documentCookies[i].trim();
            if (cookie.startsWith(cookieName + '=')) {
                return cookie.substring(cookieName.length + 1);
            }
        }
        return null;
    }

    function setCookie(name, value) {
        var currentDate = new Date();
        var expirationDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 6, currentDate.getDate());
        expirationDate.setMonth(currentDate.getMonth() + 6);

        cookieModal.classList.add('kd-cookie-modal-hidden');
        cookieModal.classList.remove('delayed');
        document.cookie = name + '=' + value + '; SameSite=None; Secure; expires=' + expirationDate.toUTCString() +';';
    }

    function clearCookie(name) {
        document.cookie = name + '=; Max-Age=0'
    }

    function showCookieModal() {
        cookieModal.classList.remove('kd-cookie-modal-hidden');

    }

    function showCookieModalDelayed() {
        cookieModal.classList.remove('kd-cookie-modal-hidden');
        cookieModal.classList.add('delayed');
    }

    function save() {
        flagSaved();
        if (marketingCookieCheckbox) {
            if (marketingCookieCheckbox.checked) {
                acceptMarketing();
            }
        }
        if (analyticsCookieCheckbox) {
            if (analyticsCookieCheckbox.checked) {
                acceptAnalytics();
            }
        }
    }

    function flagSaved() {
        marketingCookieCheckbox = document.getElementById('marketing-cookies');
        analyticsCookieCheckbox = document.getElementById('analytics-cookies');
        if (marketingCookieCheckbox) {
            marketingCookieNames.forEach((cookieName) =>
                clearCookie(cookieName),
            );
        }

        if (analyticsCookieCheckbox) {
            analyticsCookieNames.forEach((cookieName) =>
            clearCookie(cookieName)
            );
        }

        setCookie('cookie_consent', 'saved');
        cookieModal.classList.add('kd-cookie-modal-hidden');
        cookieModal.classList.remove('delayed');
    }

    function acceptAll() {
        flagSaved();
        acceptMarketing();
        acceptAnalytics();
    }

    function acceptMarketing() {
        if (marketingCookieCheckbox) {
            marketingCookieNames.forEach((cookieName, index) => 
                setCookie(cookieName, marketingCookieValues[index]),
                i++
            );
            loadIframes();
            marketingCookieCheckbox.checked = true;
        }
    }

    function acceptAnalytics() {
        if (analyticsCookieCheckbox) {
            analyticsCookieNames.forEach((cookieName, index) => 
                setCookie(cookieName, analyticsCookieValues[index]),
                i++
            );
            analyticsCookieCheckbox.checked = true;
        }
    }

    function loadIframes() {
        videoContainers.forEach(function(element) {
            var iframe = element.querySelector('.kd-consent-data').getAttribute('data-consent-data');
            var blocker = element.querySelector('.kd-consent-blocker');
            blocker.classList.add('d-none');
            element.innerHTML = iframe;    
        });
    }
}