let accordeonElements = document.querySelectorAll('.accordion-button');
let headerElement = document.getElementById('header');
let navHeight = 0;
let lastClickedID = 0;

if (headerElement) {
    headerHeight = headerElement.offsetHeight;
}

if (accordeonElements != null) {
    accordeonElements.forEach(function(accordeonElement) {
        accordeonElement.addEventListener('click', function() {
            if (accordeonElement.getAttribute('data-bs-target') != lastClickedID) {
                scrollToAcc(accordeonElement.getAttribute('data-bs-target'))
            } else {
                lastClickedID = 0;
            }
        });
    });
}

function scrollToAcc(id) {
    setTimeout(function() {
        id = id.substring(1);
        var accordeonElement = document.getElementById(id);
        console.log(accordeonElement.getBoundingClientRect().top);
        if (accordeonElement.getBoundingClientRect().top < headerHeight + 60) {
            var offset = accordeonElement.offsetTop - headerHeight - 60;
            window.scrollTo({ top: offset, behavior: 'smooth' });
            lastClickedID = '#' + id;
        }
    }, 300);
}