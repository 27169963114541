let modal = document.querySelectorAll('.kd-modal');
let modalDSGVO = document.querySelector('.kd-modal-dsgvo');
let modalContent = document.querySelectorAll('.kd-modal-content');
let modalDSGVOOpen = document.querySelectorAll('.kd-modal-dsgvo-open');
let modalClose = document.querySelectorAll('.kd-modal-close');
let modalAccept = document.querySelector('.kd-modal-accept');

//get DSGVO Checkbox
let dsgvoCheck = document.querySelector('.kd-checkbox#agree');

if (modal) {
    modal.forEach(function(element) {
        element.addEventListener("click", function (event) {
            event.preventDefault();
            element.classList.add('d-none');
        });
    });

    modalContent.forEach(function(element) {
        element.addEventListener("click", function (event) {
            event.stopPropagation();
        });
    });

    modalClose.forEach(function(element) {
        element.addEventListener("click", function () {
            modal.classList.add('d-none');
            modalDSGVO.classList.add('d-none');
            event.stopPropagation();
        });
    });

    if (modalDSGVO) {
        modalAccept.addEventListener("click", function () {
            modalDSGVO.classList.add('d-none');
            dsgvoCheck.checked = true;
        });
    }
}

if (modalDSGVO) {
    modalDSGVOOpen.forEach(function(element) {
        element.addEventListener("click", function (event) {
            event.preventDefault();
            modalDSGVO.classList.remove('d-none');
            event.stopPropagation();
        });
    });
}