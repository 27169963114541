let searchButton = document.querySelector('.kd-search-container-header-m');

// Scrollevenet Listener
const onscroll = (el, listener) => {
    el.addEventListener('scroll', listener)
}

function dropdownToggle(dropindex) {
    dropdown = document.querySelectorAll('.navbar .dropdown .dropdown-container')[dropindex];
    if (document.getElementById('navbar').classList.contains('navbar-mobile')) {
        dropdown.querySelector('.kd-dropdown-arrow').classList.toggle('fa-chevron-down');
        dropdown.querySelector('.kd-dropdown-arrow').classList.toggle('fa-chevron-up');
        dropdown.nextElementSibling.classList.toggle('dropdown-active');
    }   
}

function childDropdownToggle(childdropindex) {
    childdropdown = document.querySelectorAll('.navbar .dropdown .dropdown-dropdown-container')[childdropindex];
    if (document.getElementById('navbar').classList.contains('navbar-mobile')) {
        childdropdown.querySelector('.kd-dropdown-arrow').classList.toggle('fa-chevron-down');
        childdropdown.querySelector('.kd-dropdown-arrow').classList.toggle('fa-chevron-up');
        childdropdown.nextElementSibling.classList.toggle('dropdown-active');
    }   
}

//toggle navigation
const navbar = document.querySelector('#navbar:not(#navbar > .mobile-nav-toggle-icon):not(.kd-search-container-header-d)');
const navigation = document.querySelector(".mobile-nav-toggle");

if(navbar) {
    navbar.addEventListener('click', () => {
        toggleNavigation();
    })

    navbar.querySelectorAll('a').forEach(element => {
        element.addEventListener('click', function(event) {
            var currentURL = window.location.href.split("#")[0];
            if (element.href.includes(currentURL +'#')) {
                toggleNavigationWhenClicked();
            }
        });
    });
}

// toggle header-scrolled class
let selectHeader = document.getElementById('header');
if (selectHeader) {
    const headerScrolled = () => {
        if (window.scrollY > 100) {
            selectHeader.classList.add('kd-header-scrolled');
        } else {
            selectHeader.classList.remove('kd-header-scrolled');
        }
    }
    window.addEventListener('load', headerScrolled)
    onscroll(document, headerScrolled);
}

var navigationlist = document.querySelector('#navbar > ul');
if (navigationlist) {
    navigationlist.addEventListener("click", function (ev) {
        ev.stopPropagation();
    }, false);
}

if (searchButton) {
    searchButton.addEventListener("click", function (ev) {
        ev.stopPropagation();
    }, false);
}

function toggleNavigation() {
    var isOpened = navigation.getAttribute('aria-expanded');
    if (isOpened === 'false') {
        navigation.setAttribute('aria-expanded', 'true');
    } else {
        navigation.setAttribute('aria-expanded', 'false');
    }
    if (searchButton) {
        searchButton.classList.toggle('d-none');
    }
    
    navbar.classList.toggle('navbar-mobile');
}

function toggleNavigationWhenClicked() {
    if (navbar.classList.contains('navbar-mobile')) {
        toggleNavigation();
    }
}