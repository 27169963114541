const blogs = document.querySelector('#blogs');
const button = document.querySelector('.kd-btn-blog-more');

if (blogs) {
    let page = parseInt(blogs.getAttribute('data-page'));

    const fetchProjects = async () => {
    let url = `${window.location.href.split('#')[0]}.json/page:${page}`;
    try {
        const response = await fetch(url);
        const { html, more } = await response.json();
        button.hidden = !more;
        blogs.insertAdjacentHTML('beforeend', html);
        page++;
    } catch (error) {
        console.log('Fetch error: ', error);
    }
    }
    if (button) {
        button.addEventListener('click', fetchProjects);
    }
}